import { RouteObject } from 'react-router-dom';
// import ThemeSwitch from '../components/themeSwitch';

import DashboardPage from '../pages/DashboardPage';
import PaymentMethods from '../pages/PaymentMethods';
import Recipients from '../pages/Recipients';
import Transactions from '../pages/Transactions';
import RewardsPage from '../pages/RewardsPage';
import ConversionPage from '../pages/ConversionPage';
import Profile from '../pages/Profile';
import AuthGuard from '../AuthGuard/AuthGuard';
import ProfileSettings from '../components/profileSetting';
import KYC from '../pages/KYC';

interface MainRoutesObject {
    path: string;
    children: RouteObject[];
}

const MainRoutes: MainRoutesObject = {
    path: '/',
    
    children: [
        {
            path: 'home',
            element: <AuthGuard component={DashboardPage} />
        },
        {
            path: 'recipients',
            element: <AuthGuard component={Recipients} />
        },
        {
            path: 'PaymentMethods',
            element: <AuthGuard component={PaymentMethods} />
        },
        {
            path: 'transactions',
            element: <AuthGuard component={Transactions} />
        },
        {
            path: 'rewardsPage',
            element: <AuthGuard component={RewardsPage} />
        },
        {
            path:'conversion',
            element:<AuthGuard component={ConversionPage} />
        },
        {
            path:'profile',
            element:<AuthGuard component={Profile} />
        },
        {
            path:'user',
            element:<AuthGuard component={ProfileSettings} />
        },
        {
            path:'kyc',
            element:<AuthGuard component={KYC} />
        },
    ]
};

export default MainRoutes;
