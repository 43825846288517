
import { RouteObject, Navigate } from 'react-router-dom';
import LoginForm from '../pages/Loginform';
import SignUpForm from '../pages/SignUpForm';


interface MainRoutesObject {
    path: string;
    children: RouteObject[];
}

const MainRoutes: MainRoutesObject = {
    path: '/',
    children: [
        {
            path: '/',
            element: <Navigate to="/login" replace />
        },
        {
            path: 'login',
            element: <LoginForm />
        },
        {
            path: 'signup',
            element: <SignUpForm />
        },
        
    ]
};

export default MainRoutes;
