import React, { useEffect, useState } from "react";
import { Grid, Box, Avatar } from "@mui/material";
import { useTheme } from "../assets/themeContext";
import { getAllTransactions } from "../services/Transactionapis";
import { format } from "date-fns";
import ProgressLoader from "./Loader";
import { useNavigate } from "react-router-dom";

interface Transaction {
  recipient_details: {
    first_name: string;
  }
  recipients_id: string;
  transaction_initiation_at: string;
  usd_transfer_amount: number;
}

interface MappedTransaction {
  name: string;
  date: string;
  time: string;
  amount: number;
}

const TransactionHistory = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [transactionHistory, setTransactionHistory] = useState<
    MappedTransaction[]
  >([]);
  const [loading, setLoading] = useState(false);

  const transfer = () => {
    alert("go");
  };
  const fetchTransaction = async () => {
    setLoading(true);
    try {
      const data = await getAllTransactions();
      const mappedData: MappedTransaction[] = data.data.map(
        (transaction: Transaction) => ({
          name: transaction.recipient_details.first_name,
          date: format(
            new Date(transaction.transaction_initiation_at),
            "dd MMM yy"
          ),
          time: format(
            new Date(transaction.transaction_initiation_at),
            "hh:mm a"
          ),
          amount: transaction.usd_transfer_amount,
        })
      );
      // Set the mapped data to state
      setTransactionHistory(mappedData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, []);

  return (
    <Grid
      sx={{
        padding: "20px",
        borderRadius: "20px",
        bgcolor: theme.palette.info.light,
      }}
      item
      lg={12}
      md={12}
      xs={12}
      sm={12}
      height={"100%"}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid>
          <label
            style={{
              fontSize: "20px",
              fontWeight: 500,
              color: theme.palette.primary.main,
            }}
          >
            Transaction History
          </label>
        </Grid>
        <Grid onClick={() => navigate("/transactions")}>
          <label
            style={{
              paddingTop: "2px",
              flex: 1,
              fontSize: "16px",
              fontWeight: 500,
              color: theme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            See all
          </label>
        </Grid>
      </Grid>
      {loading ? (
        <Grid
          width={"100%"}
          height={"100%"}
          lg={12}
          sm={12}
          xs={12}
          xl={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressLoader strokeColor="#6D1ED4" fillColor="#6D1ED4" />
        </Grid>
      ) : (
        <>
          {transactionHistory.length <= 0 ? (
            <Grid>
              <Box
                marginY={15}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    paddingBottom: "20px",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: theme.palette.text.primary,
                  }}
                >
                  No Transaction History Available
                </label>
                <label
                  onClick={() => navigate("/PaymentMethods")}
                  style={{ cursor: "pointer",fontSize: "15px",
                  fontWeight: 500,
                  color: theme.palette.primary.main }}
                >
                  Click here to make a transaction
                </label>
              </Box>
            </Grid>
          ) : (
            <Grid paddingTop={2}>
              {transactionHistory.map((row, index) => (
                <Grid key={index} sx={{ paddingY: "0px", paddingX: "10px" }}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    sx={{
                      padding: "15px 10px",
                      //paddingX: "20px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      bgcolor: theme.palette.info.light,
                      transition: "box-shadow 0.3s ease",
                      marginBottom: "10px",
                      "&:hover": {
                        boxShadow: theme.palette.action.hover,
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: "rgba(109, 30, 212, 0.3)",
                        marginRight: "10px",
                      }}
                    />

                    <Grid
                      sx={{ display: "flex", flexDirection: "column", flex: 1 }}
                    >
                      <Grid>
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: theme.palette.text.primary,
                          }}
                        >
                          {row.name}
                        </label>
                      </Grid>
                      <Grid>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: theme.palette.info.main,
                          }}
                        >
                          {row.date}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid sx={{ justifyContent: "flex-end" }}>
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: theme.palette.text.primary,
                            cursor: "pointer",
                          }}
                          onClick={transfer}
                        >
                          ${row.amount}
                        </label>
                      </Grid>
                      <Grid>
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: theme.palette.info.main,
                            cursor: "pointer",
                          }}
                          //onClick={()}
                        >
                          {row.time}
                          <span style={{ fontSize: "8px" }}>EST</span>
                        </label>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default TransactionHistory;
