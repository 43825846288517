
interface Image {
  src: string;
  alt: string;
}

const indianFlag:Image={
  src: require('../assets/flags/india-flag.png'),
  alt: "india flag", 
}
const usaFlag:Image={
  src: require('../assets/flags/usa-flag.png'),
  alt: "usa flag", 
}

const cadFlag :Image={
  src: require('../assets/flags/canada-flag.png'),
  alt: "canada flag",
}


const images: { [key: string]: Image } = {
indianFlag,
usaFlag,
cadFlag
};

export default images;
export{};

